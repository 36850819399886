.login-mt{
    margin-top: 9% !important;
}

.login-head{
    margin-top: 8% !important;
}

.bg-theme-color{
    background-color:#005691 !important;
    color:#fff;
}

.txt-theme-color{
    color:#005691 !important;
}

.editprof-save-btn{
    margin-right:30px;
}

.sidebar-menu-font .active{
    color:#fff;
}

.logo-sidebar-name{
    color:#000;
    font-size: 20px;
}
//css for width of search and dropdown of Account page
.sw > label{
    width:100%
}
.search-w{
    width: 105%;
}
.search-p{
    padding-right:0%    //searchbox padding
}
.dropdown-w{
    width: 105%;
}
.dropdown-p{
    padding-left: 0%;   //dropdown-padding
}

.drpdwn{    
    width: 70px;
    background-color: #005691 !important;
    color: #fff;
    border-radius: 4px;
}
.dropdown-option{
    background-color: #fff !important;
    &:active{
    background-color: #005691 !important;
    }
}

.drpOpen{
    display: block !important;
}

.drp{    
    width: 90px;
    // background-color: #005691;
    // color: #fff;
    // border-radius: 4px;
}
.radio-btn-dashboard{
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
}

.radio-btn-p{
    padding-right: 20px;
}

.client{
    display: inline-block;
    flex-direction: column;
    margin: auto 10px;
}

.cl-p{
    padding-bottom: 14px;
}

.lease{
    margin-bottom: 14px;
}
.myclient{
    margin-bottom: 20px;
}

.gridbtn-m{
    margin-right: 5px;
    border-radius:20px;
    // color:#005691;
}

.factLabel{
    padding: 5px 0;
    margin-right: 5%
}

.mr-5{
    margin-right: 5% !important
}

.radioLabelbold{
    font-weight: bold !important
}

.radioLabelnormal{
    font-weight: normal !important
}

.trash-icon-billing-setting{
    font-size:130%;
    margin-left: 5px;
}

.switch-icon-color:checked{
    background-color: #005691 !important;
    border-color: #005691 !important;
}

.page-item:active{
    background-color: #005691 !important;
    border-color: #005691 !important;
}

.arrow-spacing{
    margin-right:10px !important;
}

.modal-footer{
    padding-right: 0 !important;
}
.card-bg{
    background-color: #edf0f5
}

.sidebar-link span{
    color: #fff !important;
}

.sidebar-link i{
    color: #fff !important;
}

.sidebar-link.active span{
    text-decoration: underline !important;
}

.sidebar-link:hover{
    background-color: #005691 !important;
}

.image-area {
    position: relative;
    width: 100px;
    height: 100px;
    background: #333;
}

.image-area img{
    width: 100px;
    height: 100px;
}

.remove-image {
    position: absolute;
    color: red;
    font-size: 20px;
    top: -20px;
    right: -20px;
    cursor: pointer;
}

.avatar-title{
    font-size: 24px !important;
    text-transform: uppercase !important;
}

.react-bs-table-no-data{
    text-align: center !important;
}

.table-height{
    min-height: 250px !important;
}

.table-data-margin{
    margin-top: 12px;
}

select.arrows {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-position: calc(100% - .5rem), 100% 0;
    background-size:  0.7em 0.7em;
    background-repeat: no-repeat;
}

.nav-tabs-custom .nav-item .nav-link.active{
    color:#005691
}
.nav-tabs-custom .nav-item .nav-link.active::after{
    background:#005691
}
.nav-tabs-custom .nav-item .nav-link:hover{
    color:#005691
}
.row-grey{
    background: rgb(238, 237, 237);
}
.ct-label{
    fill: rgb(0,0,0) !important;
}

.border-theme-color{
    border: 1px solid #005691 !important;
}

.table-max-height{
    max-height: 400px !important;
}

.tableBody {
    display: block;
    height: 300px;
    overflow-x: hidden;
}
.tableHead, .tableBody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    
}
.tableHead {
    width: 100%
}

.table > :not(caption) > * > * {
    background-color: transparent !important;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
    background-color: transparent !important;
}